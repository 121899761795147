import React, { useEffect } from 'react';
import styled from 'styled-components';
import Layout from 'components/Layout';
import Wrapper from 'components/Wrapper';
import theme from 'styles/theme';

const Main = styled.div`
	width: 320px;
	min-height: 600px;
	background: #fff;
	padding: 10px;
	${theme.media.notlarge} {
		width: 100%;
	}
`;

export default props => {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = '/widget/widget.js';
		const placeholder = document.getElementById('widget');
		placeholder.innerHTML = '';
		placeholder.innerHTML += '<div class="tdlw"></div>';
		placeholder.innerHTML += '<div class="tdlw"></div>';
		placeholder.appendChild(script);
	});
	return (
		<Layout>
			<Wrapper>
				<Main>
					<div
						dangerouslySetInnerHTML={{
							__html: `
								<link
								href="https://fonts.googleapis.com/css?family=Roboto:400,500,700,500italic,400italic,700italic|Merriweather:400,700|Source+Sans+Pro:400,600,700"
								rel="stylesheet"
								type="text/css"
							/>`,
						}}
					/>
					<div id="widget" />
				</Main>
			</Wrapper>
		</Layout>
	);
};
